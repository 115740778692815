.chromeframe {
  margin: 0.2em 0;
  background-color: #181c1e;
  color: #ccc;
  padding: 0.2em 0;
}
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background-color: #181c1e;
  z-index: 1000;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
#loader-wrapper .loader-section.section-left {
  left: 0;
}
#loader-wrapper .loader-section.section-right {
  right: 0;
}
#loader-img,
#loader-img:before,
#loader-img:after {
  border-radius: 50%;
  border: 3px solid transparent;
}
#loader-img {
  display: block;
  position: relative;
  left: 50%;
  top: 40%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-top-color: #3498db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  z-index: 1001;
}
#loader-img:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-top-color: #e74c3c;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
#loader-img:after {
  content: '';
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-top-color: #f9c922;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
#loader-text {
  display: block;
  position: relative;
  text-align: center;
  top: 50%;
  color: #fff;
  z-index: 1002;
  font-family: monospace;
  font-size: 26px;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loaded #loader-wrapper {
  visibility: hidden;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: all 0.3s 1s ease-out;
  transition: all 0.3s 1s ease-out;
}
.loaded #loader-wrapper .loader-section.section-left {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.loaded #loader-wrapper .loader-section.section-right {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.loaded #loader {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
[no-js] #loader-wrapper {
  display: none;
}
/* ==========================================================================
   Chrome Frame prompt
   ========================================================================== */
$backgroundColor: #181c1e;
$textColor: #ccc;

.chromeframe {
  margin: 0.2em 0;
  background: $backgroundColor;
  color: $textColor;
  padding: 0.2em 0;
}

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  cursor: wait;

  .loader-section {
    position: fixed;
    top: 0;
    width: 51%;
    height: 100%;
    background: $backgroundColor;
    z-index: 1000;
    -webkit-transform: translateX(0); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: translateX(0); /* IE 9 */
    transform: translateX(0); /* Firefox 16+, IE 10+, Opera */
    &.section-left {
      left: 0;
    }

    &.section-right {
      right: 0;
    }
  }
}

#loader-img,
#loader-img:before,
#loader-img:after {
  border-radius: 50%;
  border: 3px solid transparent;
}

#loader-img {
  display: block;
  position: relative;
  left: 50%;
  top: 40%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-top-color: #0409cc;
  -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  z-index: 1001;

  &:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-top-color: #000;
    -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  }

  &:after {
    content: '';
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-top-color: #ffaf00;
    -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  }
}

#loader-text {
  display: block;
  position: relative;
  text-align: center;
  top: 50%;
  color: $textColor;
  z-index: 1002;
  font-family: monospace;
  font-size: 26px;
}

@mixin keyframes-spin() {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }

  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}

@-webkit-keyframes spin {
  @include keyframes-spin();
}

@keyframes spin {
  @include keyframes-spin();
}

/* Loaded */
.loaded {
  #loader-wrapper {
    visibility: hidden;
    -webkit-transform: translateY(-100%); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: translateY(-100%); /* IE 9 */
    transform: translateY(-100%); /* Firefox 16+, IE 10+, Opera */
    -webkit-transition: all 0.3s 1s ease-out;
    transition: all 0.3s 1s ease-out;

    .loader-section {
      &.section-left {
        -webkit-transform: translateX(-100%); /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: translateX(-100%); /* IE 9 */
        transform: translateX(-100%); /* Firefox 16+, IE 10+, Opera */
        -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      &.section-right {
        -webkit-transform: translateX(100%); /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: translateX(100%); /* IE 9 */
        transform: translateX(100%); /* Firefox 16+, IE 10+, Opera */
        -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
  }

  #loader {
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
}

/* JavaScript Turned Off */
[no-js] #loader-wrapper {
  display: none;
}
