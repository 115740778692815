.warning-paper {
    width: 100%;
    height: 100%;
    padding-top: 40px;
    padding-bottom: 28px;
    padding-left: 40px;
    padding-right: 40px;
    background: #2B2B31;
    border-radius: 16px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
    display: inline-flex;
  }

  .MuiPaper-root {
    &.MuiPaper-elevation {
      background-color: #2B2B31 ;
    }
  }
  
  .user-warning-text {
    width: 100%;
    opacity: 0.8;
    text-align: center;
    color: #E8E8EB;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.25px;
    word-wrap: break-word;
  }
  
  .change-nw-button {
    background-color: #EECE7C;
    border-radius: 8px;
    overflow: hidden;
    flex-direction: column;
  }
  
  .reject-text {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    word-wrap: break-word;
  }