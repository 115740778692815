code,
pre,
input[type='code'],
textarea[type='code'] {
  font-family: monospace !important;
}

* {
  outline: none !important;
}

a:hover {
  text-decoration: none !important;
}

hr {
  border: 1px solid #3a3a3c;
}

.text-gray {
  color: #a2a5a9;
}

.v-separator {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background-color: #a2a5a9;
  }
}

.text-gradient {
  background: -webkit-linear-gradient(#ffef5e, #f7936f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.col {
  flex: 1 0 0%;
}

.numberinput--placeholder {
  &::-webkit-input-placeholder {
    /* Edge */
    color: gray 10%;
    font-size: 20px;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: gray 10%;
    font-size: 20px;
  }

  &::placeholder {
    color: gray 10%;
    font-size: 20px;
  }
}
