.change-network-button {
    border-radius: 8px;
    border: 1px #EECE7C solid;
    color: #23252A;
    width: 30%;
    height: 100%;
    justify-content: center;
    gap: 8px;
    display: flex;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 8px;
    gap: 8px;
    &:hover {
        background-color: #322B19 !important;
    }
}

.change-network-tittle {
    width: 100%;
    text-align: center;
    font-size: 20px;
}